/* eslint-disable no-undef */
import '../index.scss';

import React, { Component } from 'react';
import {
  browserName,
  browserVersion,
  isMobile,
  mobileModel,
  mobileVendor,
  osName,
  osVersion
} from 'react-device-detect';
import Loadable from 'react-loadable';
import { Route, withRouter } from 'react-router';
import { Switch } from 'react-router-dom';
import Loader from '../components/loader';
import Header from '../containers/header';
// import { useSelector } from 'react-redux';

import { LocalSessionKey } from '../burger-king-lib/constants/app-constants';
import { SessionStorage } from '../burger-king-lib/utils/storage.web';
import { ROUTES, THEME } from '../constants/app-constants';
import { deliveryAddressStorage } from '../utils/services';

const { THEMESTORAGE, DELIVERY, RESTAURANT, DINE_IN } = THEME;

const Home = Loadable({
  loader: () => import('../pages/home'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const ProductListingPage = Loadable({
  loader: () => import('../pages/product-listing'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

// const ProductDetailPage = Loadable({
//   loader: () => import('../pages/product-detail'),
//   loading: Loader,
//   delay: 300,
//   timeout: 8000
// });

const RedirectPage = Loadable({
  loader: () => import('../pages/redirectPage'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const SearchPage = Loadable({
  loader: () => import('../pages/search'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const OnboardPage = Loadable({
  loader: () => import('../pages/onboarding'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const CartPage = Loadable({
  loader: () => import('../pages/cart-payment'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const Login = Loadable({
  loader: () => import('../pages/login'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const CheckoutStatusPage = Loadable({
  loader: () => import('../pages/checkout-status'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const AccountPage = Loadable({
  loader: () => import('../pages/account'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const HelpSupportPage = Loadable({
  loader: () => import('../pages/help-support'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const TrackOrderPage = Loadable({
  loader: () => import('../pages/track-order'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const SettingsPage = Loadable({
  loader: () => import('../pages/settings'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const TermsAndConditionsPage = Loadable({
  loader: () => import('../pages/terms-and-condition'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const PrivacyPolicyPage = Loadable({
  loader: () => import('../pages/privacy-policy'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const PromotionalPrivacyPolicyPage = Loadable({
  loader: () => import('../pages/promotional-terms'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const LearnMorePage = Loadable({
  loader: () => import('../pages/learn-more-apply'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const PrivacyPolPage = Loadable({
  loader: () => import('../pages/privacy-policy1'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const StoreLocatorPage = Loadable({
  loader: () => import('../pages/store-locator'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const CrownHistoryPage = Loadable({
  loader: () => import('../pages/crown-history'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const OnlinePaymentPage = Loadable({
  loader: () => import('../pages/online-payment'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

// const JuspayPaymentPage = Loadable({
//   loader: () => import('../pages/juspay-payment'),
//   loading: Loader,
//   delay: 300,
//   timeout: 8000
// });

const OffersPage = Loadable({
  loader: () => import('../pages/offers'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const VoucherPage = Loadable({
  loader: () => import('../pages/my-vouchers'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const BKWallPage = Loadable({
  loader: () => import('../pages/bk-wall'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const VideoPlayer = Loadable({
  loader: () => import('../pages/video-player'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const CreatingBrightFuturePage = Loadable({
  loader: () => import('../pages/creating-bright-future'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const TrustTastePage = Loadable({
  loader: () => import('../pages/trust-taste'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const CareersPage = Loadable({
  loader: () => import('../pages/careers'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const FarmToForkPage = Loadable({
  loader: () => import('../pages/farm-to-fork'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const BoardCommitteePage = Loadable({
  loader: () => import('../pages/board-committee'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const Covid19Page = Loadable({
  loader: () => import('../pages/covid-19'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const FreshTastePage = Loadable({
  loader: () => import('../pages/fresh-taste'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

// const InvestorsPage = Loadable({
//   loader: () => import('../pages/investors'),
//   loading: Loader,
//   delay: 300,
//   timeout: 8000
// });

const ShareHolderContactPage = Loadable({
  loader: () => import('../pages/shareholder-grievance-redressal'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const ContactUsPage = Loadable({
  loader: () => import('../pages/write-to-us'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const InvestorRelationPage = Loadable({
  loader: () => import('../pages/investor-relations'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const InvestorCategoryPage = Loadable({
  loader: () => import('../pages/investors-category'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const InvestorQIP = Loadable({
  loader: () => import('../pages/investors-qip'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const InvestorDocument = Loadable({
  loader: () => import('../pages/investors-document'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const InvestorSubmit = Loadable({
  loader: () => import('../pages/investors-submit'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const InvestorRejection = Loadable({
  loader: () => import('../pages/investors-rejection'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const FeedbackFormPage = Loadable({
  loader: () => import('../containers/feedback-form'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const BoardDirectorsPage = Loadable({
  loader: () => import('../pages/board-directors'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const AboutUs = Loadable({
  loader: () => import('../pages/about-us'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

// const FinancialsPage = Loadable({
//   loader: () => import('../pages/financials'),
//   loading: Loader,
//   delay: 300,
//   timeout: 8000
// });

// const PostalBallotPage = Loadable({
//   loader: () => import('../pages/postal-ballot'),
//   loading: Loader,
//   delay: 300,
//   timeout: 8000
// });

// const GovernancePage = Loadable({
//   loader: () => import('../pages/corporate-governance'),
//   loading: Loader,
//   delay: 300,
//   timeout: 8000
// });

// const AnnouncementsPage = Loadable({
//   loader: () => import('../pages/corporate-announcements'),
//   loading: Loader,
//   delay: 300,
//   timeout: 8000
// });

// const StakeHoldersPage = Loadable({
//   loader: () => import('../pages/stakeholder-grievance'),
//   loading: Loader,
//   delay: 300,
//   timeout: 8000
// });

// const ShareholdingPatternsPage = Loadable({
//   loader: () => import('../pages/shareholding-patterns'),
//   loading: Loader,
//   delay: 300,
//   timeout: 8000
// });

// const AnalystMeetingsPage = Loadable({
//   loader: () => import('../pages/analyst-meetings'),
//   loading: Loader,
//   delay: 300,
//   timeout: 8000
// });

const FranchisePage = Loadable({
  loader: () => import('../pages/franchise'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});
const CustomerCarePage = Loadable({
  loader: () => import('../pages/customer-care'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const OrderStatusPage = Loadable({
  loader: () => import('../pages/order-status'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const OfferOnboarding = Loadable({
  loader: () => import('../burger-king-code/offer-onboarding'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});

const OfferListingPage = Loadable({
  loader: () => import('../containers/offer-listing'),
  loading: Loader,
  delay: 300,
  timeout: 8000
});
class Router extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: this.initialTheme(),
      cartData: {},
      address: '',
      isDisconnected: false,
      showDineinTheatre: false
    };
  }

  componentDidMount() {
    // console.log('state');
    if (isMobile) {
      SessionStorage.set(LocalSessionKey.DEVICE_BRAND, mobileVendor);
      SessionStorage.set(LocalSessionKey.DEVICE_MODEL, mobileModel);
      SessionStorage.set(LocalSessionKey.PLATFORM, 'Mweb');
    } else {
      SessionStorage.set(LocalSessionKey.OS_NAME, osName);
      SessionStorage.set(LocalSessionKey.PLATFORM, 'Dweb');
    }
    window.addEventListener('orientationchange', this.setScreenOrientation);
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
    SessionStorage.set(LocalSessionKey.OS_VERSION, osVersion);
    SessionStorage.set(
      LocalSessionKey.DEVICE_NAME,
      `${browserName}-${browserVersion}`
    );
    SessionStorage.set(LocalSessionKey.APP_VERSION, '3.0');
    SessionStorage.set('showBanner', false);
    const { history } = this.props;
    const { location } = history;
    if (
      location.search.includes('theatre_data') &&
      SessionStorage.get(LocalSessionKey.PLATFORM) === 'Mweb'
    ) {
      const { location } = this.props;
      const arr = location.search.split('&');
      let latLng = {
        lat: Number(arr[3]?.split('=')[1]),
        lng: Number(arr[4]?.split('=')[1])
      };
      latLng = JSON.stringify(latLng);
      window.sessionStorage.setItem('OUTLET_ID', arr[0].split('=')[1]);
      window.localStorage.setItem('LAT_LNG', latLng);
      window.sessionStorage.setItem('THEATRE_RESPONSE', arr[1].split('=')[1]);
      this.setState({ showDineinTheatre: true });
      history.replace('/');
    } else if (
      window.sessionStorage.getItem('THEATRE_RESPONSE') &&
      SessionStorage.get(LocalSessionKey.PLATFORM) === 'Mweb'
    ) {
      this.setState({ showDineinTheatre: true });
    } else {
      this.setState({ showDineinTheatre: false });
      window.sessionStorage.removeItem('THEATRE_RESPONSE');
    }
    if (location.search.length > 0) {
      if (location.search.includes('dinein')) {
        this.themeHandler('takeaway');
      }
      if (location.search.includes('delivery')) {
        this.themeHandler('delivery');
      }
    }
  }

  setScreenOrientation = () => {
    if (window.matchMedia('(orientation: landscape)').matches) {
      alert(
        'We do not support landscape mode. Please go back to portrait mode for the best experience '
      );
    }
  };

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') this.setState({ isDisconnected: true });
  };

  initialTheme = () => {
    const theme = SessionStorage.get(THEMESTORAGE);
    SessionStorage.set(
      LocalSessionKey.ORDER_TYPE,
      theme === RESTAURANT ? 'dinein' : theme
    );
    if (theme) return theme;
    SessionStorage.set(THEMESTORAGE, RESTAURANT);
    SessionStorage.set(LocalSessionKey.ORDER_TYPE, DINE_IN);
    return RESTAURANT;
  };

  themeHandler = value => {
    SessionStorage.set(THEMESTORAGE, value);
    SessionStorage.set(
      LocalSessionKey.ORDER_TYPE,
      value === RESTAURANT ? 'dinein' : value
    );
    this.setState({
      theme: value
    });
  };

  // setAddress = value => {
  //   // this.setState({ address: value });
  // };
  setDeliveryAddress = json => {
    deliveryAddressStorage.set(json);
  };

  loggedInToggleHandler = () => {
    const { isLoggedIn } = this.state;
    const { history } = this.props;
    this.setState({ isLoggedIn: !isLoggedIn });
    history.push('/');
  };

  onLoginSuccessHandler = () => {
    this.setState({ showLogin: false });
  };

  render() {
    const {
      theme,
      cartData,
      address,
      deliveryAddress,
      showDineinTheatre
    } = this.state;

    return (
      <>
        <Header
          themeHandler={this.themeHandler}
          theme={theme}
          showDineinTheatre={showDineinTheatre}
          count={cartData && cartData.quantity}
          address={address}
        />
        <Switch>
          <Route exact path="/" component={() => <Home theme={theme} />} />
          <Route
            path={ROUTES.PRODUCT_LISTING}
            component={() => <ProductListingPage theme={theme} />}
          />
          <Route
            path={ROUTES.M_PRODUCT_LISTING}
            component={() => <ProductListingPage theme={theme} />}
          />
          {/* <Route
            path={ROUTES.PRODUCT_DETAIL}
            component={() => <ProductDetailPage theme={theme} />}
          /> */}
          <Route
            path={ROUTES.SEARCH}
            component={() => <SearchPage theme={theme} />}
          />
          <Route
            path={ROUTES.CART}
            component={() => (
              <CartPage
                setDeliveryAddress={this.setDeliveryAddress}
                theme={theme}
                deliveryAddress={deliveryAddress}
              />
            )}
          />
          <Route
            path={ROUTES.ONBOARD}
            component={() => (
              <OnboardPage theme={theme} themeHandler={this.themeHandler} />
            )}
          />
          <Route
            path={ROUTES.LOGIN}
            component={() => (
              <Login
                loggedInToggleHandler={this.loggedInToggleHandler}
                theme={theme}
                onLoginSuccessHandler={this.onLoginSuccessHandler}
              />
            )}
          />
          <Route
            path={ROUTES.CHECKOUT_STATUS}
            component={() => <CheckoutStatusPage theme={theme} />}
          />
          <Route
            path={ROUTES.ACCOUNT}
            component={() => <AccountPage theme={theme} />}
          />
          <Route
            path={ROUTES.PL}
            component={() => <RedirectPage theme={theme} />}
          />
          <Route
            path={ROUTES.HOME2}
            component={() => (
              <RedirectPage theme={theme} themeHandler={this.themeHandler} />
            )}
          />
          <Route
            path={ROUTES.PRODUCT_DETAIL_POPUP}
            component={() => <RedirectPage theme={theme} />}
          />
          <Route
            path={`${ROUTES.ACCOUNT}/:progressKey`}
            component={() => <AccountPage theme={theme} />}
          />
          <Route
            path={ROUTES.HELP_SUPPORT}
            component={() => <HelpSupportPage theme={theme} />}
          />
          <Route
            path={ROUTES.TRACK_ORDER}
            component={() => <TrackOrderPage theme={theme} />}
          />
          <Route
            path={ROUTES.SETTINGS}
            component={() => <SettingsPage theme={theme} />}
          />
          <Route
            path={ROUTES.SETTINGS2}
            component={() => <RedirectPage theme={theme} />}
          />
          <Route
            path={ROUTES.TERMS_AND_CONDITIONS}
            component={() => <TermsAndConditionsPage theme={theme} />}
          />
          <Route
            path={ROUTES.PRIVACY_POLICY}
            component={() => <PrivacyPolicyPage theme={theme} />}
          />
          <Route
            path={ROUTES.APPLY_MORE}
            component={() => <LearnMorePage theme={theme} />}
          />
          <Route
            path={ROUTES.STORE_LOCATOR}
            component={() => (
              <StoreLocatorPage
                theme={theme}
                themeHandler={this.themeHandler}
              />
            )}
          />
          <Route
            path={ROUTES.EDIT_PROFILEE}
            component={() => <RedirectPage theme={theme} />}
          />
          <Route
            path={ROUTES.CROWN_HISTORY}
            component={() => <CrownHistoryPage theme={theme} />}
          />
          <Route
            path={ROUTES.ONLINE_PAYMENT}
            component={() => <OnlinePaymentPage theme={theme} />}
          />
          <Route
            path={ROUTES.OFFERS}
            component={() => (
              <OffersPage theme={theme} themeHandler={this.themeHandler} />
            )}
          />
          <Route
            path={ROUTES.MY_VOUCHERS}
            component={() => (
              <VoucherPage theme={theme} themeHandler={this.themeHandler} />
            )}
          />
          <Route
            path={ROUTES.BK_WALL}
            component={() => (
              <BKWallPage theme={theme} themeHandler={this.themeHandler} />
            )}
          />
          <Route
            path={ROUTES.VIDEO_PLAYER}
            component={() => <VideoPlayer theme={theme} />}
          />
          <Route
            path={ROUTES.PRIVACY_POLICY_LINK}
            component={() => <PrivacyPolicyPage theme={theme} />}
          />
          <Route
            path={ROUTES.PROMOTIONAL_PRIVACY_POLICY_LINK}
            component={() => <PromotionalPrivacyPolicyPage theme={theme} />}
          />
          <Route
            path={ROUTES.PPROMOTIONAL_PRIVACY_POLICY_LINK}
            component={() => <PromotionalPrivacyPolicyPage theme={theme} />}
          />

          <Route
            path={ROUTES.CREATING_BRIGHTER_FUTURES}
            component={() => <CreatingBrightFuturePage />}
          />
          <Route
            path={ROUTES.PRIVACY_POLICY1_LINK}
            component={() => <PrivacyPolPage theme={theme} />}
          />
          <Route
            path={ROUTES.TRUST_TASTE}
            component={() => <TrustTastePage />}
          />
          <Route path={ROUTES.CAREERS} component={() => <CareersPage />} />
          <Route
            path={ROUTES.FARM_TO_FORK}
            component={() => <FarmToForkPage />}
          />
          <Route
            path={ROUTES.FEEDBACK}
            exact
            component={() => <Home theme={theme} />}
          />
          <Route path={ROUTES.COVID_19} component={() => <Covid19Page />} />
          <Route
            path={ROUTES.FRESH_TASTE}
            component={() => <FreshTastePage />}
          />
          <Route
            path={ROUTES.INVESTORS}
            component={() => <InvestorRelationPage />}
          />
          <Route
            path={ROUTES.INVESTOR_CATEGORY}
            component={() => <InvestorCategoryPage theme={theme} />}
          />
          <Route
            exact
            path={ROUTES.INVESTOR_CATEGORY_QIP}
            component={() => <InvestorQIP />}
          />
          <Route
            path={ROUTES.INVESTOR_DOCUMENT}
            component={() => <InvestorDocument />}
          />
          <Route
            path={ROUTES.INVESTOR_SUBMIT}
            component={() => <InvestorSubmit />}
          />
          <Route
            path={ROUTES.INVESTOR_REJECTION}
            component={() => <InvestorRejection />}
          />
          <Route
            path={ROUTES.CONTACT_SHAREHOLDER}
            component={() => <ShareHolderContactPage />}
          />
          <Route
            path={ROUTES.BOARD_DIRECTORS}
            component={() => <BoardDirectorsPage theme={theme} />}
          />
          {/* <Route
            path={ROUTES.FINANCIALS}
            component={() => <FinancialsPage theme={theme} />}
          /> */}
          <Route
            path={ROUTES.ABOUT_US}
            component={() => <AboutUs theme={theme} />}
          />
          {/* <Route
            path={ROUTES.CORPORATE_GOVERNANCE}
            component={() => <GovernancePage theme={theme} />}
          /> */}
          {/* <Route
            path={ROUTES.STAKEHOLDERS}
            component={() => <StakeHoldersPage theme={theme} />}
          /> */}
          {/* <Route
            path={ROUTES.CORPORATE_ANNOUNCEMENTS}
            component={() => <AnnouncementsPage theme={theme} />}
          />

          <Route
            path={ROUTES.CORPORATE_GOVERNANCE}
            component={() => <GovernancePage theme={theme} />}
          /> */}
          <Route
            path={ROUTES.COMMITEE}
            component={() => <BoardCommitteePage />}
          />
          <Route path={ROUTES.FRANCHISE} component={() => <FranchisePage />} />
          <Route
            path={ROUTES.CUSTOMER_CARE}
            component={() => <CustomerCarePage />}
          />
          {/* <Route
            path={ROUTES.POSTAL_BALLOT}
            component={() => <PostalBallotPage theme={theme} />}
          /> */}
          <Route
            path={ROUTES.TERMS}
            component={() => <TermsAndConditionsPage isSeparatePage />}
          />
          {/* <Route
            path={ROUTES.SHAREHOLDING_PATTERN}
            component={() => <ShareholdingPatternsPage theme={theme} />}
          />

          <Route
            path={ROUTES.ANALYST_MEETINGS}
            component={() => <AnalystMeetingsPage theme={theme} />}
          /> */}
          <Route
            path={ROUTES.HELP_SUPPORT_LINK}
            component={() => <HelpSupportPage isSeparatePage theme={theme} />}
          />
          <Route exact path={ROUTES.ORDER_STATUS} component={OrderStatusPage} />
          {/* <Route
            exact
            path={ROUTES.JUSPAY_REDIRECT_URL}
            component={JuspayPaymentPage}
          /> */}
          <Route
            exact
            path={ROUTES.CART_OFFERS}
            component={() => <OfferListingPage theme={theme} />}
          />
          <Route path={ROUTES.CONTACT_US} component={() => <ContactUsPage />} />
          <Route
            path={ROUTES.OFFER_ONBOARDING}
            component={() => <Home theme={theme} />}
          />
        </Switch>
      </>
    );
  }
}

export default withRouter(Router);
